.o-popup {
  $root: &;
  border: 0;
  border-radius: rem(8);
  color: $white;
  max-height: rem(640);
  max-width: rem(797);
  outline: 0;
  overflow: auto;
  position: fixed;
  width: 100%;

  &-otp {
    max-width: rem(578);

    #{$root}_title {
      background-color: $la-salle-green;
      max-width: 100% !important;
      border: 0;
      border-radius: 0;

      span {
        color: $white;
        text-transform: capitalize;
        max-width: rem(327);
        margin: auto;
      }
    }

    #{$root}_content {
      padding: 0 rem(20) rem(24);
    }
  }

  &-policy {
    @include scroll-bars;

    @include desktop-up {
      max-width: rem(1100);
    }



    #{$root}_body {
      @include tablet-down {
        padding: 0;
      }
    }

    #{$root}_title {
      max-width: rem(528);

      @include tablet-down {
        max-width: rem(272);
      }

      span {
        font-weight: 700;
        @include font-base(30, 48);

        @include tablet-down {
          @include font-base(22, 30);
        }
      }
    }

    #{$root}_content {
      padding: 0 rem(30) rem(40);

      @include tablet-down {
        padding: 0 rem(18) rem(27);
      }

      h3 {
        margin-top: 0;
        color: $pigment;
        margin-bottom: rem(12);

        @include tablet-down {
          margin-bottom: rem(7);
        }

        @include desktop-up {
          @include font-base(24, 30);
        }
      }

      p {
        margin-bottom: rem(9);

        @include tablet-down {
          @include font-base(14, 22);
        }
      }

      ul {
        padding-left: rem(40);

        li {
          &:not(:first-child) {
            margin-top: rem(9);
          }

          @include tablet-down {
            @include font-base(14, 22);
          }
        }
      }

      a {
        color: $arsenic;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }

  @include tablet-down {
    max-height: rem(601);
  }

  // @include scroll-bars;

  @include mobile-tablet {
    max-width: 80vw;
  }

  @include mobile-down {
    max-width: 90vw;
  }

  &_title {
    align-items: center;
    background-clip: padding-box, border-box;
    // background-image: $inner-border-gold-linear;
    background-origin: border-box;
    border: solid rem(2.5) transparent;
    border-radius: rem(10);
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: rem(400);
    position: relative;
    text-align: center;

    &:not(#{$root}-otp) {
      padding: rem(10) rem(15);
    }

    @include mobile-down {
      height: auto;
      padding: rem(10) rem(35);
      @include font-base(18, 24);
    }

    &::before {
      // background: $pale-gold-linear1;
      border-radius: rem(10);
      content: '';
      height: calc(100% - #{rem(2.5)});
      position: absolute;
      width: calc(100% - #{rem(2.5)});
    }

    span {
      color: $pigment;
      font-weight: 500;
      position: relative;
      @include font-base(20, 27);

      @include tablet-down {
        @include font-base(20, 27);
      }
    }
  }

  &_close {
    cursor: pointer;
    height: rem(14);
    position: absolute;
    right: rem(21);
    top: rem(25);
    width: rem(14);
    z-index: 3;

    &::before {
      background-color: $black;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      width: 100%;
    }

    &::after {
      background-color: $black;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(-45deg);
      width: 100%;
    }
  }

  &-container {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background: rgba(14, 14, 14, 0.65);
    // border: 1px solid $uni-california-gold;
    overflow: auto;
    position: relative;
    width: 100%;

    &-noScrollContainer {
      overflow: hidden;
    }
  }

  &:focus {
    outline: 0;
  }

  &-overlay {
    animation: none;
    background: rgba($black, 0.3) !important;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-modal;

    &::after {
      backdrop-filter: blur(8px);
      background: rgba($black, 0.74) !important;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  &_body {
    background: $white;

    @include small-mobile {
      padding: rem(30) rem(14);
    }

    #{$root}-otp & {
      padding: 0;
    }
  }

  &_content {
    margin-top: rem(32);
    color: $black;

    #{$root}-otp & {
      margin-top: rem(16);
    }

    @include mobile-down {
      margin-top: rem(14);
    }
  }

  &_button {
    margin: 0 auto;
    max-width: rem(230);

    &.mobile-reverse {
      @include mobile-down {
        display: flex;
        flex-direction: column-reverse;
      }

      .a-button {
        &:first-child {
          @include mobile-down {
            margin-top: rem(16);
          }
        }

        &:last-child {
          margin-top: 0 !important;
        }
      }
    }

    &.flex {
      display: flex;
      justify-content: space-between;
      max-width: 100%;

      .a-button {
        max-width: rem(200);
      }
    }
  }

  &-otp {
    .m-otp-input {
      margin-top: rem(21.42);
    }
  }

}

.o-modal-portal-open {
  opacity: 1;
}

.reactmodal-body-open,
.reactmodal-html-open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: scale(0) translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: scale(1) translateX(-100px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: scale(0);
}

.ReactModal__Overlay {
  align-items: center;
  background: rgba($black, 0.3) !important;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  // transform: translateY(-100%);
  transition: all 500ms ease-in-out;
  z-index: $z-modal;

  &::after {
    background: rgba($black, 0.74) !important;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &--open {
    overflow: hidden;

    @include tablet-up {
      padding-right: rem(7);
    }
  }

  &--after-open {
    opacity: 1;
    transform: scale(1);
  }

  &--before-close {
    opacity: 0;
    transform: scale(0);
  }
}

.overflow {
  overflow: hidden;
}