.t-mainLayout {
  &_header {
    width: 100%;
    height: rem(220);
    position: relative;

    @include desktop-down {
      height: rem(172);
    }

    @include mobile-down {
      height: rem(156);
    }

    &::before {
      background-color: $la-salle-green;
      content: '';
      position: absolute;
      top: 0;
      left: -59px;
      width: 107%;
      height: 100%;
      border-bottom-right-radius: 100%;
      border-bottom-left-radius: 100%;

      @include desktop-down {
        left: -37px;
        width: 110%;
      }

      @include mobile-down {
        left: -29px;
        width: 115%;
      }
    }
  }

  &_logo {
    position: absolute;
    top: rem(33);
    left: 50%;
    width: rem(555);
    transform: translateX(-50%);

    @include desktop-down {
      width: rem(427);
    }

    @include tablet-down {
      width: rem(347);
    }

    @include mobile-down {
      width: rem(300);
    }

    img {
      width: 100%;
    }
  }

  &_body {
    width: 100%;
    padding-top: rem(50);
    padding-bottom: rem(65);
  }

  &_footer {
    width: 100%;
    background-color: $la-salle-green;
    position: relative;
    padding: rem(40) 0;

    @include mobile-down {
      padding: rem(28) 0;
    }

    &_info {
      color: $white;

      @include tablet-up {
        text-align: center;

      }

      h3,
      h4 {
        margin-top: 0;
      }

      h3 {
        margin-bottom: 5px;

        @include desktop-up {
          font-size: rem(22);
        }
      }

      a {
        color: $white;
        text-decoration: underline;
      }

      p {
        margin-bottom: 7px;
      }
    }

    .o-container {
      position: relative;
      height: 100%;
    }

    img {
      width: rem(65);
      position: absolute;
      bottom: 100%;
      right: 0;

      @include mobile-down {
        width: rem(45);
        right: rem(10);
      }
    }
  }
}

.wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}