// Primary Colors
$british-racing-green: rgb(7, 76, 49); // #074c31
$android-green: rgb(161, 192, 56); // #a1c038
$pigment: rgb(36, 62, 144); //#243E90
$rich-black: rgb(0, 9, 47); //00092f
$red: rgb(255, 0, 0); //#FF0000
$black: rgb(0, 0, 0); //#000000
$permanent-geranium-lake: rgb(221, 35, 39); //#dd2327
$spanish-gray: rgb(147, 149, 152); //#939598
$arsenic: rgb(65, 64, 66); //#414042
$la-salle-green: rgb(0, 127, 45); //#007F2D

// Text Colors
$raisin-black: rgb(38, 38, 38); // #262626
$davys-grey: rgb(89, 89, 89); // #595959
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$snow: rgb(250, 250, 250); // #fafafa
$white: rgb(255, 255, 255); // #ffffff

// Palette Colors
$amazon: rgb(57, 112, 90); // #39705a
$xanadu: rgb(106, 148, 131); // #6a9483
$aero-blue: rgb(207, 236, 225); // #cfece1
$green-ryb: rgb(118, 168, 38); // #76a826
$june-bud: rgb(180, 205, 96); // #b4cd60
$pale-goldenrod: rgb(217, 230, 175); // #d9e6af

// Toast Colors
$toast-green: rgb(96, 168, 66); // #60a842
$sinopia: rgb(206, 49, 0); // #ce3100
$vivid-amber: rgb(206, 142, 0); // #ce8e00
$true-blue: rgb(0, 95, 206); // #005fce

// Others
$urobilin: rgb(223, 179, 37); // #dfb325
$antique-bronze: rgb(103, 88, 34); // #675822
$plum: rgb(161, 66, 150); // #a14296
$cyber-grape: rgb(87, 58, 116); // #573a74
$bg-white-smoke: rgb(246, 246, 246); // #f6f6f6

// Overlays